.App {
  text-align: center;
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: scroll;
  background: #5DBCD2;
}

.Setup {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: scroll;
  background: #5DBCD2;
  /* background: white; */
}

.AppGeneral {
  text-align: center;
  /* height: 100%; */
  position: absolute;
  left: 0;
  width: 100%;
  /* background: white; */
}

.Report {
  text-align: center;
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: scroll;
  background: white;
}

.App-logo {
  
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.body{
  display: flex;
  padding: 10px;
}
.Selection{
  width: 100%;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border: solid #609;
  border-radius: 6px;
  background: #ffffff;
  padding: 5px;
  /* width: 100%; */
  height: 44px;
  color: black;
  text-align: left;
}

.InputSelection{
  width: 100%;
  padding-left: 5px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border: solid #609 1px;
  background: #ffffff;
 
  /* width: 100%; */
  height: 30px;
  color: black;
  text-align: left;
}

.dateinput{
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  text-align: start;
  padding-left: 5px;
  border: solid #609 1px;
 
    background: #ffffff;
    
    width: 100%;
    height: 30px; 
}


.inputScreen{
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  text-align: start;
  padding-left: 5px;
  border: solid #609 1px;
 
    background: #ffffff;
    
    width: 100%;
    height: 30px;  
}

  .input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    border: solid #609;
    border-radius: 6px;
    background: #ffffff;
    padding: 20px; 
    width: 100%;
    height: 10px;  
  }

  .generalInput{
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    border: solid #609;
    border-radius: 4px;
    background: #ffffff;
    padding: 20px;
    width: 80%;
    height: 10px;
  }

  .generalSelect{
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    border: solid #609;
    border-radius: 5px;
    
    padding: 5px;
    width:  80%;
    height: auto;
    color: #000000;
  }

  .NotRange{
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    border: solid #609;
    border-radius: 5px;
      background: #dadada;
      padding: 5px; 
      width: 80%;
      height: 40px; 
      pointer-events: none;
  }
  .inputProcess {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    border: solid #609;
    border-radius: 5px;
      background: #ffffff;
      padding: 5px; 
      width: 80%;
      height: 40px;  
    }
  

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.field {
  width: 100%;
 
  border-radius: 4px;
  position: relative;
  background-color: rgba(255,255,255,0.3);
  transition: 0.3s all;
}
.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.general-style {
  margin: 5px; 
  background: no-repeat padding-box #fff;
}

.tower {
  height: 210mm;
  width: 297mm;
  position: relative;
  text-align: center;
}

.footer-space {
  height: 35px;
}

.a4-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 220px;
}

.il-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
}

.a4-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 20px;
}

.a4-footerr {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 270px;
}

.page {
  page-break-inside: avoid;
}

table.print-content, table.print-trt-report, table.print-calendar-content {
  font-size: 14px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* border:0px solid black; */
  /* border-collapse: collapse !important; */
}

table.print-liner-content, table.print-bead {
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 20px;
 

}

table.print-content tr { line-height: 2px; }

/* table.table-bordered{
  border:1px solid blue;
  margin-top:20px;
} */

table.print-content > thead > tr {
  height: 1px;
}

table.print-content > thead > tr > th{
  border:1px solid black;
  line-height: 15px;
}

table.print-content > tbody > tr > td{
  border:1px solid black;
}

table.print-bead > thead > tr > th{
  border:1px solid black;
 
}
table.print-bead > tbody > tr > td{
  border:1px solid black;
}

table.print-trt-report > tbody > tr > td {
  border:1px solid black;
  line-height: 30px;
}

table.print-trt-report > thead > tr > th {
  border:1px solid black;
  line-height: 20px;
}

table.print-calendar-content > thead > tr > th{
  border: 1px solid black;
}
table.print-calendar-content > tbody > tr > td{
  border: 1px solid black;
}

table.print-liner-content > thead > tr > th{
  border: 1px solid black;
}
table.print-liner-content > tbody > tr > td{
  border: 1px solid black;
}

/* table.print-content th,
table.print-content td {
  padding: .2rem .4rem;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
} */

@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}

@media print {

  .print-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 220px;
  }

  .no-print {
    display: none
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
  }
  .head {
    margin-top: "-250px";
  }

  .a4-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 220px;
  }

  table {
    width: 100%;
  }
  
  thead { display:table-header-group }
  tfoot { display:table-footer-group }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .content {
    margin-top: 0;
  }

  body {
    margin: 0px;
    counter-reset: my-sec-counter;
  }
  .graph-image img{display:inline;}
}

div.alwaysbreak { page-break-before: always;}

/* .navbar-light .navbar-nav .nav-link.active {
  color: blue !important;
} */

label.name-label.form-label {
  font-weight: 500;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.tolerance {
  height: 28px;
  margin-top: 0px;
  width: 90px;
  padding-top: 3px;
  margin-left: 7px;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.from-tolerance {
  height: 28px;
  margin-top: 6px;
  width: 50px;
  padding-top: 3px;
  margin-left: 7px;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.export{
  margin-left: 10px;
  border-radius: 7px;
  height: 30px;
  margin-top: 5px;
}

.process_table {
  /* width: 110%; */
  margin-bottom: 1rem;
  color: #212529;
}

.pagination {
  margin-top: 0px;
}
.pagination span {
cursor: pointer;
color: black;
float: left;
padding: 8px 16px;
text-decoration: none;
transition: background-color .3s;
border: 1px solid #ddd;
}

.pagination-active {
background-color: #0099FF;
color: white;
border: 1px solid #0099FF;
}

.row-padding {
  padding:0 8px
}

.w3-center{text-align:center!important}
.w3-third {float:left;width:100%;background: white; height: 150px;}

@media (min-width:601px){
  .w3-third{width:33.33333%;}
}

.fakeimg {
  height: 130px;
  background: #0099FF;
}

.mixer-cardimage {
  background-image: url('./images/banbury-rubber-mixer-machine.jpg');
  width: 100px;
  height: 100px;
}

.extruder-cardimage {
  background-image: url('./images/extruder-machine.jpg');
  width: 100px;
  height: 100px;
}

.calendar-cardimage {
  background-image: url('./images/calender-machine.png');
  width: 100px;
  height: 100px;
}

.curing-cardimage {
  background-image: url('./images/tire-curing.jpg');
  width: 100px;
  height: 100px;
}

.bead-cardimage {
  background-image: url('./images/tire-bead.jpg');
  width: 100px;
  height: 100px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  /* color: #d1d1d1; */
  color: #ffffff;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #ffffff;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .nav-link.active {
  color: yellow !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #e1eaf5 !important;
}

.graph-7{background: url('./images/eurogrip.jpg') no-repeat;}
.graph-image img{display: none;}

@media print{
  .graph-image img{display: inline;}
}

div.polaroid {
  width: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}

div.container {
  padding: 10px;
}

div.polaroid:hover {
  width: 100%;
  text-align: center;
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.table-header-color {
  background-color: rgb(65, 60, 105);
  color: rgb(255, 255, 255);
}
